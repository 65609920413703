<template>
	<div>
		<!-- If modal have a "pui-select" or "pui-date-field", set property :overflow="false" -->
		<!-- If fixed width is needed, set property :widthDialog="XXX" -->
		<pui-modal-dialog-form
			:titleText="$t('modal.equipamientoqrequip.generaqr.title')"
			:modelName="modelName"
			:dialogName="equipamientoqrequipGeneraqrModal"
			:onOk="onOkGeneraqrModal"
			:okText="getTextOK()"
			:onShow="onShowGeneraqrModal"
			disableCancel
		>
			<template slot="message" slot-scope="data">
				<v-form action ref="generaqrForm" @submit.prevent :id="`form-equipamientoqrequip`">
					<v-layout row wrap>
						<v-flex xs12 md12 v-if="showContenido">
							<pui-text-field
								:id="`contenidoqr-equipamientoqrequip`"
								v-model="data.modalData.contenidoqr"
								:label="$t('modal.equipamientoqrequip.generaqr.contenidoqr')"
								disabled
								toplabel
								maxlength="21"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap>
						<v-flex xs12 md12 v-if="showContenido">
							<pui-text-field
								:id="`feccaducidad-equipamientoqrequip`"
								v-model="data.modalData.feccaducidadstr"
								:label="$t('modal.equipamientoqrequip.generaqr.feccaducidad')"
								disabled
								toplabel
								maxlength="21"
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout row wrap v-if="showQR">
						<v-flex xs12 md12>
							<v-img
								:id="`qrimg-equipamientoqrequip`"
								class="pointer"
								:src="getDataQR(data.modalData)"
								height="300px"
								width="300px"
								style="border: 2px solid red !important"
							/>
						</v-flex>
					</v-layout>
					<v-layout row wrap v-if="showQR">
						<v-flex xs12 md4>
							<v-btn color="primary" @click="printQR(data.modalData)" depressed class="elevation-0" style="margin-top:23px">
								{{ $t('modal.equipamientoqrequip.generaqr.print') }}
							</v-btn>
						</v-flex>
						<v-flex xs12 md4>
							<v-btn color="primary" @click="downloadQR(data.modalData)" depressed class="elevation-0" style="margin-top:23px">
								{{ $t('modal.equipamientoqrequip.generaqr.download') }}
							</v-btn>
						</v-flex>
						<v-flex xs12 md4>
							<v-btn
								color="primary"
								@click="closeModal(data.modalData)"
								depressed
								class="elevation-0"
								style="margin-top:23px;margin-left:13px"
							>
								{{ $t('modal.equipamientoqrequip.generaqr.cerrar') }}
							</v-btn>
						</v-flex>
					</v-layout>
				</v-form>
			</template>
		</pui-modal-dialog-form>
	</div>
</template>

<script>
import dateTimeUtils from 'pui9-components/src/dateTimeUtils';
export default {
	name: 'equipamientoqrequip-modals',
	data() {
		return {
			equipamientoqrequipGeneraqrModal: 'equipamientoqrequipGeneraqrAction',
			showContenido: false,
			showQR: false,
			refreshSelect: 0
		};
	},
	props: {
		modelName: {
			required: true,
			type: String
		},
		filtro: {
			required: false,
			type: Number
		},
		modelNameOrigen: {
			required: false,
			type: String
		}
	},
	watch: {
		showQR(/*value*/) {
			//console.log(value);
			var divBTN = document.getElementById('equipamientoqrequipGeneraqrAction-btn-ok').parentElement;
			divBTN.style.display = 'none';
		}
	},
	methods: {
		onOkGeneraqrModal(/*modalData*/) {
			//if (this.showQR)
			return new Promise(resolve => {
				this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
				resolve(true);
			}); //cierra el dialog
			//}
		},
		getQR(modalData) {
			const params = {
				idequipamientoqr: modalData.idequipamientoqr,
				idequipamiento: modalData.idequipamiento
			};

			const url = this.$store.getters.getModelByName(this.modelName).url.generaQR;
			var title = this.$puiI18n.t('puiaction.notifyTitle') + ' > ' + this.$puiI18n.t('modal.equipamientoqrequip.generaqr.title');
			this.$puiRequests.getRequest(
				url,
				params,
				response => {
					console.log(response.data);
					modalData.qrimg = response.data.qrimg;
					modalData.qr = response.data.qr;
					modalData.contenidoqr = response.data.matricula + '/' + response.data.idequipamientoqr;
					modalData.matricula = response.data.matricula;
					modalData.idequipamientoqr = response.data.idequipamientoqr;
					const dateFormat = this.$store.getters.dateFormat;
					const timeFormat = this.$store.getters.timeFormat;
					modalData.feccaducidadstr = dateTimeUtils.getLocalFormattedDate(response.data.feccaducidad, `${dateFormat} ${timeFormat}`);
					this.showQR = true;
					this.showContenido = true;
					this.refreshSelect++;
				},
				e => {
					// Error response, do code and not close modal
					let message = this.$puiI18n.t('puiaction.notifyError');
					if (e.response && e.response.data) {
						message = e.response.data.message;
					}
					this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelName);
					this.$puiNotify.error(message, title);
				}
			);
		},
		getTextOK() {
			//if (this.showQR)
			return this.$t('modal.equipamientoqrequip.generaqr.okCerrartext');
			//else return this.$t('modal.equipamientoqrequip.generaqr.okGenerartext');
		},
		onShowGeneraqrModal(modalData) {
			this.showContenido = false;
			this.showQR = false;
			if (modalData.idequipamientoqr) {
				modalData.contenidoqr = modalData.matricula + '/' + modalData.idequipamientoqr;
				this.showContenido = true;
			} else if (!modalData.idequipamiento) {
				modalData.idequipamiento = this.filtro.rules[0].data;
			}

			this.getQR(modalData);
		},
		printQR(modalData) {
			console.log(modalData);
			var opc = 'status=no,toolbar=no,menubar=no,scrollbars=no,location=no,directories=no,height=500,width=600';
			var v = window.open('', this.$t('modal.equipamientoqrequip.generaqr.title'), opc);
			var img = '<img src="' + modalData.qrimg + '"/>';
			v.document.write('<html>\n<head>\n<title>' + document.title + '</title>\n</head>\n<body>\n' + img + '\n</body>\n</html>\n');
			setTimeout(function() {
				v.document.close(), v.focus(), v.print(), v.close();
			}, 1e3);
		},
		downloadQR(modalData) {
			console.log(modalData);
			const link = document.createElement('a');
			link.href = modalData.qrimg;
			link.download = modalData.matricula + '-' + modalData.idequipamientoqr + '.png';
			link.click();
		},
		closeModal(/*modalData*/) {
			document.getElementById('equipamientoqrequipGeneraqrAction-btn-ok').click();
			if (this.modelNameOrigen) this.$puiEvents.$emit('onPui-action-running-ended-' + this.modelNameOrigen);
		},
		getDataQR(modalData) {
			return modalData.qrimg;
		}
	}
};
</script>
